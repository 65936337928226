import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import logo from '../../../assets/logo.png';

/**
 * NavBarProps
 */
export interface NavBarProps {
  // Optional title to navbar
  title?: string;
}

/**
 * NavBar component
 *
 * @param {NavBarProps}
 */
export const NavBar = ({ title }: NavBarProps) => (
  <AppBar position="static" color="inherit">
    <Toolbar>
      <Link to="/">
        <img src={logo} alt="PwC" />
      </Link>
      {title && <h6>{title}</h6>}
    </Toolbar>
  </AppBar>
);
