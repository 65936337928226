import React, { FC, useEffect, useState } from 'react';
import { getTokenContents } from '../helpers';

/**
 * Authenticate props
 *
 * @interface AuthenticateProps
 */
interface AuthenticateProps {
  // Children rendered within
  children: React.ReactNode;
  // Force login if no token present in localstorage
  forceLogin?: boolean;
  // Component hook for login
  onForceLogin?: any;
  // Limit for the expiry (unix time)
  threshold?: number;
  // Warning hook for token expiry
  expiryWarning?: () => void;
}

/**
 * Authenticate component
 */
export const Authenticate: FC<AuthenticateProps> = ({
  forceLogin = true,
  expiryWarning,
  threshold = 2,
  children,
  onForceLogin,
}) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem('id_token')
  );
  useEffect(() => {
    const expiryInterval = (exp: number) => {
      let grace = Math.floor((exp - Date.now() / 1000) / 60);
      if (grace < threshold && expiryWarning) expiryWarning();
    };

    let interval: NodeJS.Timeout;
    if (!token)
      if (localStorage.getItem('id_token'))
        setToken(localStorage.getItem('id_token'));

    if (token && expiryWarning)
      interval = setInterval(
        () => expiryInterval(getTokenContents(token).exp),
        60000
      );

    return () => clearInterval(interval);
  }, [forceLogin, token, expiryWarning, threshold]);

  if (token) return <>{children}</>;
  return forceLogin ? onForceLogin : <p>Login disabled</p>;
};
