import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import jwt_decode from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';

// Storage keys
export const STORAGE_KEYS = {
  // Key for id_token
  TOKEN: 'id_token',
  // Key for denied_url
  DENIED_URL: 'denied_url',
};

/**
 * Token payload
 */
export type TokenPayload = {
  upn?: string;
  sub: string;
  uid: string;
  exp: number;
};

/**
 * Token response
 */
export type TokenResponse = {
  // Access token
  access_token: string;
  // Id token
  id_token: string;
  // Token type
  token_type: string;
  // Unix time expiration
  expires_in: number;
};

/**
 * Auth identity config
 */
const AUTH_CONFIG = {
  CLIENT_ID: process.env.REACT_APP_IDENTITY_CLIENTID || '',
  AUTHORIZATION_URL: process.env.REACT_APP_IDENTITY_AUTHURL || '',
  REDIRECT_URL: process.env.REACT_APP_IDENTITY_REDIRECTURL || '',
  SCOPES: process.env.REACT_APP_IDENTITY_SCOPES || '',
};

/**
 * Get authorization url
 */
export const getAuthURL = `${AUTH_CONFIG.AUTHORIZATION_URL}client_id=${
  AUTH_CONFIG.CLIENT_ID
}&redirect_uri=${AUTH_CONFIG.REDIRECT_URL}&scope=${AUTH_CONFIG.SCOPES.split(
  ','
).join(' ')}&state=${uuidv4()}&response_type=code&nonce=${uuidv4()}`;

/**
 * Decode token and return it as an object
 *
 * @param {string} token string
 * @returns {TokenPayload} decoded token
 */
export const getTokenContents = (token: string): TokenPayload =>
  jwt_decode(token);

/**
 * Get axios instance with preconfigurations
 *
 * @returns Axios instance
 */
export const axiosWrapper = () => {
  const instance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 30000,
  });

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      // Ignore login redirect URL to prevent infinite loop
      const isAuthCallback =
        window.location.href.startsWith(AUTH_CONFIG.REDIRECT_URL) &&
        window.location.pathname;
      if (error.response && error.response.status === 401 && !isAuthCallback) {
        localStorage.removeItem(STORAGE_KEYS.TOKEN);
        localStorage.setItem(STORAGE_KEYS.DENIED_URL, window.location.href);
        window.location.replace(getAuthURL);
      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.request.use((request: AxiosRequestConfig) => {
    request.headers['Authorization'] = `Bearer ${localStorage.getItem(
      STORAGE_KEYS.TOKEN
    )}`;
    return request;
  });
  return instance;
};

/**
 * Get tokens from auth API
 *
 * @param {string} code
 * @return {*}  {Promise<TokenResponse>}
 */
export const getTokens = async (code: string): Promise<TokenResponse> => {
  return axios
    .get(
      `${process.env.REACT_APP_AUTH_API}/token?code=${code}&redirect_uri=${AUTH_CONFIG.REDIRECT_URL}`
    )
    .then((response: AxiosResponse) => {
      return response.data;
    })
    .catch((error: AxiosError) => {
      console.log(error);
      return null;
    });
};
